import React from 'react';

import { Skeleton } from '@mui/material';

import { StoreSelectors, useStoreSelector } from '../../../../store';

import { ReportTypeEnum } from '../../../../types';

import {
  useDialog,
  useReportsGenerateReportMutation,
  useReportsGetDataFilterQuery,
  useSnakeBar,
} from '../../../../hooks';

import { ListRow } from '../../../../components';

import { ReportSelect } from './ReportSelect';
import { ReportToolbar } from './ReportToolbar';
import { ReportDataTable } from './ReportDataTable';
import { ReportDataTableWithConnections } from './ReportDataTableWithConnections';
import { ReportDataContainer } from './ReportDataContainer';
import type { OnChangeSelected, ReportData } from './ReportManagementPage.types';
import { normalizeReportData } from './ReportManagementPage.utils';
import { ReportGenerationConfirmDialog } from './ReportGenerationConfirmDialog';

const DEFAULT_REPORT_TYPE = ReportTypeEnum.EmployeeInformation;

export const ReportManagementPage = () => {
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: generateReport, isLoading: isGeneratingReport } = useReportsGenerateReportMutation();

  const {
    dialogState: showReportGenerationConfirmDialog,
    openDialog: openReportGenerationConfirmDialog,
    closeDialog: closeReportGenerationConfirmDialog,
  } = useDialog();

  const userId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectUserId(state.AppReducer));
  const ownerId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));

  const [selectedReportType, setSelectedReportType] = React.useState<ReportTypeEnum>(DEFAULT_REPORT_TYPE);
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [selectedData, setSelectedData] = React.useState<ReportData>([]);
  const [reportData, setReportData] = React.useState<ReportData>([]);
  const [employmentEndDateData, setEmploymentEndDateData] = React.useState('');

  const { data, isLoading, isFetching } = useReportsGetDataFilterQuery({
    variables: { userId },
    options: {
      enabled: !!userId,
      onSuccess: data => {
        setReportData(normalizeReportData(data));
      },
    },
  });

  const loading = isLoading || isFetching;
  const disabledActionsWhileLoading = loading || isGeneratingReport;
  const isActiveEmployeeCountReportSelected = selectedReportType === ReportTypeEnum.ActiveEmployeeCount;
  const isEmployeeInformationReportSelected = selectedReportType === ReportTypeEnum.EmployeeInformation;
  const isSummaryOfRegulationDeviationsSelected = selectedReportType === ReportTypeEnum.SummaryOfRegulationDeviations;
  const isInterruptedSynchronizationSelected = selectedReportType === ReportTypeEnum.InterruptedSynchronization;
  const isLatestConnectionRunSelected = selectedReportType === ReportTypeEnum.LatestConnectionRun;
  const isCollectiveSalaryChangeSelected = selectedReportType === ReportTypeEnum.CollectiveSalaryChange;
  const showOnlyManualConnection = selectedReportType === ReportTypeEnum.CollectiveSalaryChange;
  const isControlToolSelected = selectedReportType === ReportTypeEnum.ControlTool;

  const isReportWithoutConnections = isSummaryOfRegulationDeviationsSelected;

  const isReportWithConnections =
    isActiveEmployeeCountReportSelected ||
    isEmployeeInformationReportSelected ||
    isInterruptedSynchronizationSelected ||
    isLatestConnectionRunSelected ||
    isCollectiveSalaryChangeSelected ||
    isControlToolSelected;

  const showReportDataTable = !loading && isReportWithoutConnections;
  const showReportDataTableWithConnections = !loading && isReportWithConnections;

  const LoadingSkeleton = React.useMemo(() => {
    return (
      <ReportDataContainer>
        {new Array(20).fill(null).map((_, idx) => {
          return (
            <ListRow key={idx}>
              <Skeleton width={'100%'} height={40} />
            </ListRow>
          );
        })}
      </ReportDataContainer>
    );
  }, []);

  const clearSelection = React.useCallback(() => {
    setSelectedData([]);
    setIsAllSelected(false);
  }, []);

  const onChangeReportType = React.useCallback(
    (reportType: ReportTypeEnum) => {
      clearSelection();
      setSelectedReportType(reportType);
    },
    [clearSelection],
  );

  const onChangeSelectAll = React.useCallback(
    (checked: boolean) => {
      if (checked) {
        setSelectedData(JSON.parse(JSON.stringify(reportData)));
      }
      if (!checked) {
        setSelectedData([]);
      }
      setIsAllSelected(checked);
    },
    [reportData],
  );

  const onChangeSearchQuery = React.useCallback((searchQuery: string) => {
    setSearchInput(searchQuery);
  }, []);

  const onChangeEmploymentEndDate = React.useCallback((employmentEndDateQuery: string) => {
    setEmploymentEndDateData(employmentEndDateQuery);
  }, []);

  const onChangeSelected = React.useCallback<OnChangeSelected>(newReportData => {
    setIsAllSelected(false);
    setSelectedData(newReportData);
  }, []);

  const getDateFromString = (dateString: string): string | null => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (regex.test(dateString)) {
      const dateParts = dateString.split('-');
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed
      const day = parseInt(dateParts[2], 10);

      const date = new Date(Date.UTC(year, month, day));
      return date.toISOString();
    }

    return null;
  };

  const submitRequest = React.useCallback(() => {
    closeReportGenerationConfirmDialog();

    if (selectedData.length > 0) {
      const method = 'generateReport';

      generateReport(
        {
          ownerId,
          reportIdentifier: selectedReportType,
          employers: selectedData.map(({ subEmployers, ...employer }) => employer),
          subOwners: (data?.subOwners || []).map(({ employers, ...subOwner }) => subOwner),
          endDateFilter: getDateFromString(employmentEndDateData),
        },
        {
          onSuccess: () => {
            clearSelection();
            showSuccessSnakeBar({ method });
          },
          onError: () => {
            showErrorSnakeBar({ method });
          },
        },
      );
    }
  }, [
    clearSelection,
    closeReportGenerationConfirmDialog,
    data?.subOwners,
    employmentEndDateData,
    generateReport,
    ownerId,
    selectedData,
    selectedReportType,
    showErrorSnakeBar,
    showSuccessSnakeBar,
  ]);

  const onClickGenerateReport = React.useCallback(() => {
    if (selectedData.length > 0) {
      openReportGenerationConfirmDialog();
    }
  }, [selectedData, openReportGenerationConfirmDialog]);

  return (
    <>
      <ReportSelect
        defaultSelectedReportType={DEFAULT_REPORT_TYPE}
        onChangeReportType={onChangeReportType}
        disabledActionsWhileLoading={disabledActionsWhileLoading}
      />

      <ReportToolbar
        isReportWithConnections={isReportWithConnections}
        isAllSelected={isAllSelected}
        onChangeSelectAll={onChangeSelectAll}
        onChangeSearchQuery={onChangeSearchQuery}
        onClickGenerateReport={onClickGenerateReport}
        disabledActionsWhileLoading={disabledActionsWhileLoading}
        onChangeEmploymentEndDate={onChangeEmploymentEndDate}
        onChangeReportType={onChangeReportType}
        selectedReportType={selectedReportType}
      />

      {loading && LoadingSkeleton}

      {showReportDataTable && (
        <ReportDataTable
          reportData={reportData}
          selectedData={selectedData}
          setSelected={onChangeSelected}
          searchQuery={searchInput}
          disabledActionsWhileLoading={disabledActionsWhileLoading}
        />
      )}

      {showReportDataTableWithConnections && (
        <ReportDataTableWithConnections
          reportData={reportData}
          reportType={selectedReportType}
          selectedData={selectedData}
          setSelected={onChangeSelected}
          searchQuery={searchInput}
          disabledActionsWhileLoading={disabledActionsWhileLoading}
          onlyManual={showOnlyManualConnection}
        />
      )}

      {showReportGenerationConfirmDialog && selectedData.length > 0 && (
        <ReportGenerationConfirmDialog
          open={showReportGenerationConfirmDialog}
          isReportWithConnections={isReportWithConnections}
          reportData={selectedData}
          reportType={selectedReportType}
          onClose={closeReportGenerationConfirmDialog}
          onConfirm={submitRequest}
        />
      )}
    </>
  );
};
