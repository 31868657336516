import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { IntlMessage } from 'app/components/Intl';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import FileUploadArea, { FileType } from '../../../../components/FileUploadArea';

const UploadFileDialog = props => {
  const { open, setOpen, onConfirm, types } = props;
  const [hasFiles, setHasFiles] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(undefined);

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
      <DialogTitle id="upload-file-dialog">
        <FormattedMessage id="upload.file.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText marginBottom={theme => theme.spacing(1)}>
          <FormattedMessage id="upload.file.helpMessage" />
        </DialogContentText>
        <FileUploadArea
          types={types ?? [FileType.Csv, FileType.Xml]}
          disabled={false}
          showSuccessMessage={false}
          onChange={files => {
            setHasFiles(files.length > 0);
            setUploadedFiles(files);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setOpen(false)}>
          <IntlMessage value={'no'} />
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm(uploadedFiles);
          }}
          disabled={!hasFiles}
        >
          {'Run'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

UploadFileDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default UploadFileDialog;
