import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Grid } from '@mui/material';

import PropTypes from 'prop-types';
import * as Yup from 'yup';

import Autocomplete from '@mui/material/Autocomplete';
import MuiTextField from '@mui/material/TextField';

import { DividerStyled, FormLabelStyled, GridItemStyled, TextFieldStyled } from './CreateEmployerForm.styles';
import { TranslationKeys } from '../../../../translations';
import { employerService } from '../../Services/EmployerService';

const CreateEmployerForm = props => {
  const {
    employer,
    owners,
    setEmployersHolding,
    setSubOwners,
    subOwners,
    setSelectedOwner,
    selectedOwner,
    setSelectedParent,
    setEmployer,
  } = props;

  // uses
  const intl = useIntl();

  // data
  const [employerErrorField, setEmployerErrorField] = useState(null);
  const [employerErrorMessage, setEmployerErrorMessage] = useState(null);

  const [subOwner, setSubOwner] = useState(null);

  const validationSchema = Yup.object().shape({
    employerName: Yup.string().trim().strict().max(255).required(),
    employerEmail: Yup.string().trim().strict().max(255).email(),
    employerPhone: Yup.string().trim().strict().max(255),
    internalNumber: Yup.string()
      .trim()
      .strict()
      .max(255)
      .test(
        'internalNumber',
        intl.formatMessage({ id: TranslationKeys.validationMessage_internalNumber_alreadyExists }),
        async value => {
          if (!value) return true;

          try {
            await employerService.validateField('internalNumber', value);
          } catch (error) {
            if (error.response?.status === 422) {
              return false;
            }
            throw error;
          }
          return true;
        },
      ),
    contactName: Yup.string().trim().strict().max(255),
    contactEmail: Yup.string().trim().strict().max(255).email(),
    contactPhone: Yup.string().trim().strict().max(255),
    chamberOfCommerce: Yup.string().trim().strict().max(255),
    sectorCode: Yup.string().trim().strict().max(50),
    payrollTaxNumber: Yup.string().trim().strict().max(255),
    address: Yup.string().trim().strict().required(),
    zipCode: Yup.string().trim().strict().max(255).required(),
    city: Yup.string().trim().strict().max(255).required(),
  });

  // events
  const handleChange = field => event => {
    employer[field] = event.target.value;

    Yup.reach(validationSchema, field)
      .validate(employer[field])
      .then(() => {
        setEmployerErrorField(null);
        setEmployerErrorMessage(null);
      })
      .catch(e => {
        setEmployerErrorField(field);
        setEmployerErrorMessage(e.message);
      });

    props.onEmployerChange(field, event.target.value);
  };

  const ownerAutoComplete = useMemo(() => {
    return (
      <Autocomplete
        name="ownerId"
        options={owners}
        getOptionLabel={option => {
          return option.ownerName;
        }}
        disableClearable={true}
        isOptionEqualToValue={(item, current) => {
          if (typeof current === 'string') {
            return item.value === current;
          }
          const currentValue = current.value ? current.value : current.ownerId;

          return item.ownerId === currentValue;
        }}
        onChange={(e, value) => {
          setSelectedParent(null);
          setSubOwner(null);
          setSelectedOwner(value);
          setEmployersHolding(value.employers);
          setSubOwners(value.subOwners);
          setEmployer({
            ...employer,
            ownerId: value.ownerId,
            parentId: null,
          });
        }}
        renderInput={params => <MuiTextField {...params} name="ownerId" variant="outlined" fullWidth />}
      />
    );
  }, [employer, owners, setEmployer, setEmployersHolding, setSelectedOwner, setSelectedParent, setSubOwners]);

  const subOwnersAutoComplete = useMemo(() => {
    return (
      <Autocomplete
        name="subOwnerId"
        options={subOwners}
        getOptionLabel={option => {
          return option.ownerName;
        }}
        disableClearable={false}
        isOptionEqualToValue={(item, current) => {
          if (typeof current === 'string') {
            return item.value === current;
          }
          const currentValue = current.value ? current.value : current.ownerId;

          return item.ownerId === currentValue;
        }}
        value={subOwner}
        onChange={(e, value) => {
          setSelectedParent(null);
          if (!value) {
            setSubOwner(null);
            setEmployersHolding(selectedOwner.employers);
            setEmployer({
              ...employer,
              ownerId: selectedOwner.ownerId,
              parentId: null,
            });
          } else {
            setSubOwner(subOwners.find(item => item.ownerId === value.ownerId));
            setEmployersHolding(value.employers);
            setEmployer({
              ...employer,
              ownerId: value.ownerId,
              parentId: null,
            });
          }
        }}
        renderInput={params => <MuiTextField {...params} name="subOwnerId" variant="outlined" fullWidth />}
      />
    );
  }, [employer, subOwner, selectedOwner, setEmployer, setEmployersHolding, setSelectedParent, subOwners]);

  // template
  return (
    <Grid sx={{ width: '100%' }} container spacing={2}>
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.name" />
          {' *'}
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          error={employerErrorField === 'employerName'}
          fullWidth
          helperText={employerErrorField === 'employerName' ? employerErrorMessage : null}
          name="employer.employerName"
          placeholder={intl.formatMessage({ id: 'employers.name' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('employerName')}
        />
      </GridItemStyled>

      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.chamberOfCommerce" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="chamberOfCommerce"
          placeholder={intl.formatMessage({ id: 'employers.chamberOfCommerce' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('chamberOfCommerce')}
        />
      </GridItemStyled>

      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.owner" />
          {' *'}
        </FormLabelStyled>
        {ownerAutoComplete}
      </GridItemStyled>
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.subOwner" />
        </FormLabelStyled>
        {subOwnersAutoComplete}
      </GridItemStyled>

      {/* secorCode / payrollTaxNumber */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.sectorCode" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="sectorCode"
          placeholder={intl.formatMessage({ id: 'employers.sectorCode' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('sectorCode')}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.payrollTaxNumber" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="payrollTaxNumber"
          placeholder={intl.formatMessage({ id: 'employers.payrollTaxNumber' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('payrollTaxNumber')}
        />
      </GridItemStyled>

      {/* address / city */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.address" />
          {' *'}
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="address"
          placeholder={intl.formatMessage({ id: 'employers.address' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('address')}
          error={employerErrorField === 'address'}
          helperText={employerErrorField === 'address' ? employerErrorMessage : null}
        />
      </GridItemStyled>

      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.zipCode" />
          {' *'}
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="zipCode"
          placeholder={intl.formatMessage({ id: 'employers.zipCode' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('zipCode')}
          error={employerErrorField === 'zipCode'}
          helperText={employerErrorField === 'zipCode' ? employerErrorMessage : null}
        />
      </GridItemStyled>

      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.city" />
          {' *'}
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="city"
          placeholder={intl.formatMessage({ id: 'employers.city' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('city')}
          error={employerErrorField === 'city'}
          helperText={employerErrorField === 'city' ? employerErrorMessage : null}
        />
      </GridItemStyled>

      <GridItemStyled item xs={6} style={{ display: 'none' }}>
        <FormLabelStyled>
          <FormattedMessage id="employers.numberOfEmployees" />
        </FormLabelStyled>
        <TextFieldStyled
          small
          color="secondary"
          disabled
          name="numberOfEmployees"
          placeholder={intl.formatMessage({ id: 'employers.numberOfEmployees' }).toUpperCase()}
          type="number"
          variant="outlined"
          onChange={handleChange('numberOfEmployees')}
        />
      </GridItemStyled>

      {/* email / phone */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.employerEmail" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          error={employerErrorField === 'employerEmail'}
          fullWidth
          helperText={employerErrorField === 'employerEmail' ? employerErrorMessage : null}
          name="employerEmail"
          placeholder={intl.formatMessage({ id: 'employers.email' }).toUpperCase()}
          required
          type="email"
          variant="outlined"
          onChange={handleChange('employerEmail')}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.employerPhone" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="employerPhone"
          placeholder={intl.formatMessage({ id: 'employers.employerPhone' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('employerPhone')}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.internalNumber" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="employerPhone"
          placeholder={intl.formatMessage({ id: 'employers.internalNumber' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('internalNumber')}
          error={employerErrorField === 'internalNumber'}
          helperText={employerErrorField === 'internalNumber' ? employerErrorMessage : null}
        />
      </GridItemStyled>

      <DividerStyled />

      {/* contact name */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.contactName" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="contactName"
          placeholder={intl.formatMessage({ id: 'employers.contactName' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('contactName')}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6} />

      {/* contact email / phone */}
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.contactEmail" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="contactEmail"
          placeholder={intl.formatMessage({ id: 'employers.contactEmail' }).toUpperCase()}
          type="email"
          variant="outlined"
          onChange={handleChange('contactEmail')}
        />
      </GridItemStyled>
      <GridItemStyled item xs={6}>
        <FormLabelStyled>
          <FormattedMessage id="employers.contactPhone" />
        </FormLabelStyled>
        <TextFieldStyled
          color="secondary"
          fullWidth
          name="contactPhone"
          placeholder={intl.formatMessage({ id: 'employers.contactPhone' }).toUpperCase()}
          variant="outlined"
          onChange={handleChange('contactPhone')}
        />
      </GridItemStyled>
    </Grid>
  );
};

CreateEmployerForm.propTypes = {
  employer: PropTypes.object,
  employerShowHolding: PropTypes.bool,
  employerIsHolding: PropTypes.bool,
  onEmployerChange: PropTypes.func,
};

CreateEmployerForm.defaultProps = {
  employerShowHolding: true,
  employerIsHolding: false,
};

export default CreateEmployerForm;
