import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { setLocale } from 'yup';

import { Chip } from '@mui/material';

import { formatDate } from '../../../../../utils/formatter/dateTimeFormatter';

import { TranslationKeys } from '../../../../../translations';

import { isString } from '../../../../../utils';

import { ConnectionSourcePackageTypeEnum } from '../../../../../types';

import type { Employee, Employer } from '../../../../../types';

import { useDialog, useEmployeeDeleteMutation, useSnakeBar } from '../../../../../hooks';

import { RUN } from '../../../../../common/Authorization/permissions';
import { CONNECTION } from '../../../../../common/Authorization/entities';

import type { SingleSelectedState, TableSelectedState } from '../../../../../store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from '../../../../../store';

import {
  ActionLink,
  ConfirmDialog,
  FilterableTable,
  HasAccessTo,
  IsOwnerAdmin,
  KebabMenu,
  type KebabMenuItem,
} from '../../../../../components';

import { EmployeeWizard } from '../../../../Employees/Components';

import { EmployeeUploadFormDialog } from './EmployeeUploadFormDialog';
import { EmployeeCreationModeSelectorDialog } from './EmployeeCreationModeSelectorDialog';
import { EmployeeTableNameWithDisabledSync } from './EmployeeTableNameWithDisabledSync';
import { EmployeeCSCUploadFormDialog } from './EmployeeCSCUploadFormDialog';

export const EmployeeOverviewTab = () => {
  const dispatch = useStoreDispatch();
  const intl = useIntl();

  const { item: employer, loading: employerLoading } = useStoreSelector<SingleSelectedState<Employer>>(state =>
    StoreSelectors.EmployerSelector.selectEmployer(state.EmployerReducer),
  );

  const {
    loading: employeesLoading,
    loaded: employeesLoaded,
    items: employees,
    page,
    pageSize,
    totalCount,
    filters: employeeFilters,
  } = useStoreSelector<TableSelectedState<Employee>>(state =>
    StoreSelectors.EmployerSelector.selectEmployees(state.EmployerReducer),
  );

  const {
    loading: employeeTypesLoading,
    loaded: employeeTypesLoaded,
    items: employeeTypes,
  } = useStoreSelector<TableSelectedState>(state =>
    StoreSelectors.OwnerSelector.selectEmployeeTypes(state.OwnerReducer),
  );

  const {
    dialogState: showEmployeeWizard,
    openDialog: openEmployeeWizard,
    closeDialog: closeEmployeeWizard,
  } = useDialog();

  const {
    dialogState: showEmployeeCreationModeSelectorDialog,
    openDialog: openEmployeeCreationModeSelectorDialog,
    closeDialog: closeEmployeeCreationModeSelectorDialog,
  } = useDialog();

  const {
    dialogState: showEmployeeCSCModeSelectorDialog,
    openDialog: openEmployeeCSCModeSelectorDialog,
    closeDialog: closeEmployeeCSCModeSelectorDialog,
  } = useDialog();

  const {
    dialogState: showEmployeeUploadFormDialog,
    openDialog: openEmployeeUploadFormDialog,
    closeDialog: closeEmployeeUploadFormDialog,
  } = useDialog();

  const [confirmDeleteModal, setConfirmDeleteModal] = React.useState(false);
  const [employeeIdToDelete, setEmployeeIdToDelete] = React.useState<Employee['employeeId'] | null>(null);
  const [connectionIdToDelete, setConnectionIdToDelete] = React.useState<Employee['connectionId'] | null>(null);
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();

  const [employerConnections, setEmployerConnections] = React.useState<Employer['connections'] | null>(null);
  const employerId = employer?.employerId;
  const ownerId = employer?.ownerId ?? null;
  const isOwnerAdmin = IsOwnerAdmin();
  const disabledIfNoAccessToConnectionRun = !HasAccessTo(CONNECTION, RUN);
  const searchQuery = employeeFilters.employee || null;
  const selectedEmployeeType = employeeFilters.employeeTypeId || null;
  const selectedEmployeeStatus = employeeFilters.status || 'active';
  const selectedEmployeeProduct = employeeFilters.productId?.[0] || null;

  React.useEffect(() => {
    if (ownerId) {
      dispatch(StoreActions.OwnerActions.employeeTypes.requestData({ ownerId }));
    }
  }, [dispatch, ownerId]);

  React.useEffect(() => {
    if (employer && employer?.connections) {
      setEmployerConnections(
        employer.connections.filter(connection => connection.connectionType === ConnectionSourcePackageTypeEnum.Manual),
      );
    }
  }, [employer]);

  React.useEffect(() => {
    if (employerId) {
      // @ts-ignore
      dispatch(StoreActions.EmployerActions.employees.setEmployerId(employerId));
    }
  }, [dispatch, employerId]);

  const onDeleteActionClick = React.useCallback((employeeId, connectionId) => {
    setConfirmDeleteModal(true);
    setEmployeeIdToDelete(employeeId);
    setConnectionIdToDelete(connectionId);
  }, []);

  const { mutate: deleteEmployeeMutation } = useEmployeeDeleteMutation();

  const deleteEmployee = React.useCallback(() => {
    if (employeeIdToDelete) {
      const method = 'deleteEmployee';

      deleteEmployeeMutation(
        { connectionId: connectionIdToDelete, employeeId: employeeIdToDelete },
        {
          onSuccess: () => {
            // @ts-ignore
            dispatch(StoreActions.EmployerActions.employees.setEmployerId(employerId));

            setEmployeeIdToDelete(null);
            setConnectionIdToDelete(null);
            showSuccessSnakeBar({ method });
          },
          onError: (error: any) => {
            showErrorSnakeBar({ method, message: error.message });
          },
        },
      );
    }
  }, [
    employeeIdToDelete,
    deleteEmployeeMutation,
    connectionIdToDelete,
    dispatch,
    employerId,
    showSuccessSnakeBar,
    showErrorSnakeBar,
  ]);

  const buildRowActions = React.useCallback(
    (employee: Employee) => {
      if (employee.connectionType !== 'manual-input' || !isOwnerAdmin) {
        return;
      }

      const actions: Array<KebabMenuItem> = [
        {
          element: <FormattedMessage id={TranslationKeys.global_delete} />,
          onClick: () => onDeleteActionClick(employee.employeeId, employee.connectionId),
          disabled: employee.connectionType !== 'manual-input' || !isOwnerAdmin,
        },
      ];

      return <KebabMenu items={actions} />;
    },
    [isOwnerAdmin, onDeleteActionClick],
  );

  const afterSubmitEmployeeUploadFormDialog = React.useCallback(() => {
    closeEmployeeCreationModeSelectorDialog();
    closeEmployeeUploadFormDialog();
  }, [closeEmployeeCreationModeSelectorDialog, closeEmployeeUploadFormDialog]);

  const employeesData = React.useMemo(() => {
    if (employeesLoading || employerLoading) {
      return [];
    }

    return (employees || []).map(employee => {
      const connection = employer?.connections.find(connection => connection.connectionId === employee.connectionId);
      return {
        ...employee,
        connectionName: `${connection?.connectionName} [${connection?.product?.productName.toUpperCase()}]`,
        employerId: employer?.employerId,
      };
    });
  }, [employees, employeesLoading, employer?.connections, employer?.employerId, employerLoading]);

  const onFilterChange = React.useCallback(
    ({ name, value }) => {
      if (name === 'action_add') {
        openEmployeeCreationModeSelectorDialog();
      } else if (name === 'action_csc') {
        openEmployeeCSCModeSelectorDialog();
      } else if (employerId) {
        let filterValue = value;

        if (name === 'productId' || name === 'connectionIds') {
          filterValue = [value];
        }

        if (!value) {
          dispatch(StoreActions.EmployerActions.employees.removeFilter(name));
          return;
        }

        dispatch(StoreActions.EmployerActions.employees.applyFilter(name, filterValue));
      }
    },
    [dispatch, employerId, openEmployeeCreationModeSelectorDialog, openEmployeeCSCModeSelectorDialog],
  );

  const pagination = React.useMemo(() => {
    return {
      pageSize,
      totalCount,
      page,
    };
  }, [totalCount, page, pageSize]);

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (employerId) {
        if (typeof rowSize !== 'undefined') {
          dispatch(StoreActions.EmployerActions.employees.setPageSize(rowSize));
        }
        if (typeof page !== 'undefined') {
          dispatch(StoreActions.EmployerActions.employees.changePage(page));
        }
      }
    },
    [dispatch, employerId],
  );

  const onSortChange = React.useCallback(
    sortBy => {
      if (employerId) {
        dispatch(StoreActions.EmployerActions.employees.sortTable(sortBy));
      }
    },
    [dispatch, employerId],
  );

  const employeeStatusSelectItems = React.useMemo(() => {
    return [
      {
        element: intl.formatMessage({ id: TranslationKeys.employees_filter_status }),
        value: 'all',
        default: selectedEmployeeStatus === 'all',
      },
      {
        element: intl.formatMessage({ id: TranslationKeys.employees_status_active }),
        value: 'active',
        default: selectedEmployeeStatus === 'active',
      },
      {
        element: intl.formatMessage({ id: TranslationKeys.employees_status_inactive }),
        value: 'inactive',
        default: selectedEmployeeStatus === 'inactive',
      },
    ];
  }, [intl, selectedEmployeeStatus]);

  const employeeTypeSelectItems = React.useMemo(() => {
    const items = (employeeTypes || []).map(type => {
      return {
        element: type.name,
        value: type.employeeTypeId || null,
        default: selectedEmployeeType === type.employeeTypeId,
      };
    });

    items.unshift({
      element: intl.formatMessage({ id: TranslationKeys.employees_filter_types }),
      value: '',
      default: !selectedEmployeeType,
    });

    return items;
  }, [employeeTypes, intl, selectedEmployeeType]);

  const productsSelectItems = React.useMemo(() => {
    const items = (employer?.connections || []).reduce<{ element: string; value: string; default?: boolean }[]>(
      (acc, connection) => {
        const product = connection.product;

        if (product && acc.findIndex(item => item.value === product.productId) === -1) {
          acc.push({
            element: product.productName,
            value: product.productId,
          });
        }

        return acc;
      },
      [],
    );

    items.unshift({
      element: intl.formatMessage({ id: TranslationKeys.employees_filter_products }),
      value: '',
      default: !selectedEmployeeProduct,
    });

    return items;
  }, [employer?.connections, intl, selectedEmployeeProduct]);

  const connectionSelectItems = React.useMemo(() => {
    const items = (employer?.connections || []).reduce<{ element: string; value: string; default?: boolean }[]>(
      (acc, connection) => {
        const connectionId = connection.connectionId;
        const connectionName = connection.connectionName;

        if (acc.findIndex(item => item.value === connectionId) === -1) {
          acc.push({
            element: connectionName,
            value: connectionId,
          });
        }

        return acc;
      },
      [],
    );

    items.unshift({
      element: intl.formatMessage({ id: TranslationKeys.employees_filter_connections }),
      value: '',
      default: true,
    });

    return items;
  }, [employer?.connections, intl]);

  const filters = React.useMemo(() => {
    return [
      {
        type: 'select',
        name: 'status',
        items: employeeStatusSelectItems,
      },
      {
        type: 'select',
        name: 'employeeTypeId',
        loading: employeeTypesLoading || !employeeTypesLoaded,
        items: employeeTypeSelectItems,
      },
      {
        type: 'select',
        name: 'productId',
        loading: employerLoading,
        items: productsSelectItems,
      },
      {
        type: 'select',
        name: 'connectionIds',
        loading: employerLoading,
        items: connectionSelectItems,
      },
      {
        type: 'search',
        name: 'employeeSearch',
        label: intl.formatMessage({ id: TranslationKeys.employees_search }),
        initialValue: searchQuery,
      },
      {
        type: 'action',
        name: 'action_add',
        label: intl.formatMessage({ id: TranslationKeys.employees_add }),
        disabled: disabledIfNoAccessToConnectionRun,
      },
      {
        type: 'action',
        name: 'action_csc',
        label: intl.formatMessage({ id: TranslationKeys.employees_collectiveSalaryChange }),
        disabled: disabledIfNoAccessToConnectionRun,
      },
    ];
  }, [
    employeeStatusSelectItems,
    employeeTypesLoading,
    employeeTypesLoaded,
    employeeTypeSelectItems,
    employerLoading,
    productsSelectItems,
    connectionSelectItems,
    intl,
    searchQuery,
    disabledIfNoAccessToConnectionRun,
  ]);

  const headers = React.useMemo(() => {
    return [
      {
        name: 'name',
        title: <FormattedMessage id={TranslationKeys.employees_name} />,
        sortable: true,
      },
      {
        name: 'status',
        title: <FormattedMessage id={TranslationKeys.employees_status} />,
      },
      {
        name: 'type',
        title: <FormattedMessage id={TranslationKeys.employees_type} />,
      },
      {
        name: 'employmentStart',
        title: <FormattedMessage id={TranslationKeys.employees_detail_employmentStart} />,
        sortable: true,
      },
      {
        name: 'employmentEnd',
        title: <FormattedMessage id={TranslationKeys.employees_detail_employmentEnd} />,
        sortable: true,
      },
      {
        name: 'connectionName',
        title: <FormattedMessage id={TranslationKeys.insurers_connection} />,
        sortable: true,
      },
      {
        name: 'productType',
        title: <FormattedMessage id={TranslationKeys.products_title} />,
        sortable: true,
      },
      {
        name: 'actions',
        isActionColumn: true,
      },
    ];
  }, []);

  const setNameRowValue = React.useCallback((employee: Employee) => {
    const syncStatus = employee?.synchronization?.status;
    const syncStatusArray = Object.values(employee?.synchronization?.status || {}).filter(status => !isString(status));
    const hasDisabledSync = syncStatusArray.some(status => !status);
    const actionNameLink = <ActionLink to={`${employee.employeeId}`}>{employee?.personName?.fullName}</ActionLink>;

    if (hasDisabledSync && syncStatus) {
      return <EmployeeTableNameWithDisabledSync name={actionNameLink} syncStatus={syncStatus} />;
    }

    return actionNameLink;
  }, []);

  const rowMapper = React.useCallback(
    employee => {
      const isActive = employee?.status === 'active';
      const statusText = intl.formatMessage({ id: `employees.status.${employee.status}` });
      const productTypeText =
        employee.productType && intl.formatMessage({ id: `products.type.${employee.productType}` });

      return {
        style: {
          inactive: !isActive,
        },
        data: {
          name: setNameRowValue(employee),
          status: isActive ? <strong>{statusText}</strong> : statusText,
          type: employee.employeeType || '-',
          employmentStart: formatDate(employee?.employment?.startDate) || '-',
          employmentEnd: formatDate(employee?.employment?.endDate) || '-',
          productType: productTypeText && <Chip label={productTypeText} color={'secondary'} size={'small'} />,
          connectionName: employee && (
            <ActionLink to={`/employers/${employee.employerId}/connections/${employee.connectionId}/info`}>
              {employee.connectionName}
            </ActionLink>
          ),
          actions: buildRowActions(employee),
        },
      };
    },
    [intl, setNameRowValue, buildRowActions],
  );

  setLocale({
    array: {
      min: ({ min }) => ({ key: TranslationKeys.employees_error_tooShort, values: { min } }),
      max: ({ max }) => ({ key: TranslationKeys.employees_error_fileTooBig, values: { max } }),
    },
  });

  return (
    <>
      <FilterableTable
        columns={headers}
        loading={employeesLoading || !employeesLoaded}
        rows={employeesData}
        pagination={pagination}
        filters={filters}
        onFilterChange={onFilterChange}
        onPaginationChange={onPaginationChange}
        onSortChange={onSortChange}
        rowMapper={rowMapper}
      />
      {showEmployeeCreationModeSelectorDialog && (
        <EmployeeCreationModeSelectorDialog
          show={showEmployeeCreationModeSelectorDialog}
          onClose={closeEmployeeCreationModeSelectorDialog}
          onOpenWizard={openEmployeeWizard}
          onOpenUploadFormDialog={openEmployeeUploadFormDialog}
        />
      )}
      {showEmployeeCSCModeSelectorDialog && !!employerId && (
        <EmployeeCSCUploadFormDialog
          show={true}
          onClose={closeEmployeeCSCModeSelectorDialog}
          afterSubmit={afterSubmitEmployeeUploadFormDialog}
          employerId={employerId}
          employerConnections={employerConnections}
        />
      )}
      {showEmployeeWizard && !!employerId && (
        <EmployeeWizard
          employeeTypes={employeeTypes}
          employerConnections={employerConnections}
          connectionsLoading={employerLoading}
          onClose={closeEmployeeWizard}
          employerId={employerId}
        />
      )}
      {showEmployeeUploadFormDialog && !!employerId && (
        <EmployeeUploadFormDialog
          show={showEmployeeUploadFormDialog}
          onClose={closeEmployeeUploadFormDialog}
          afterSubmit={afterSubmitEmployeeUploadFormDialog}
          employerId={employerId}
          employerConnections={employerConnections}
        />
      )}
      {confirmDeleteModal && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.employees_confirmDeleteTitle} />}
          open={confirmDeleteModal}
          setOpen={setConfirmDeleteModal}
          onConfirm={deleteEmployee}
        >
          <FormattedMessage id={TranslationKeys.employees_confirmDeleteMessage} />
        </ConfirmDialog>
      )}
    </>
  );
};
