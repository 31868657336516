import { useMutation } from '@tanstack/react-query';
import { ocrFileService } from '../../../Domain/Connections/Services/Connection/OCR';
import type { UpdateOCRFIleStatusParams } from '../../../Domain/Connections/Services/Connection/OCR';

type UpdateOCRFileStatus = UpdateOCRFIleStatusParams;

const mutationFn = (data: UpdateOCRFileStatus) => {
  return ocrFileService.patchOCRFileStatus(data);
};

export const useConnectionUpdateOCRFileStatusMutation = () => {
  return useMutation<unknown, unknown, UpdateOCRFileStatus>(mutationFn);
};
