import React from 'react';
import { useIntl } from 'react-intl';
import { ConnectionEventTypes } from '../types';

export const useConnectionEventTypeSelectItems = () => {
  const intl = useIntl();

  const connectionEventTypes = React.useMemo(() => {
    return [
      {
        value: ConnectionEventTypes.NewEmployee,
        element: intl.formatMessage({ id: 'events.type.newEmployee' }),
      },
      {
        value: ConnectionEventTypes.ContractUpdated,
        element: intl.formatMessage({ id: 'events.type.contractUpdated' }),
      },
      {
        value: ConnectionEventTypes.EmployeeInformationUpdated,
        element: intl.formatMessage({ id: 'events.type.employeeInformationUpdated' }),
      },
      {
        value: ConnectionEventTypes.EmployeeAddressUpdated,
        element: intl.formatMessage({ id: 'events.type.employeeAddressUpdated' }),
      },
      {
        value: ConnectionEventTypes.EmployeePartTimePercentageUpdated,
        element: intl.formatMessage({ id: 'events.type.employeePartTimePercentageUpdated' }),
      },
      {
        value: ConnectionEventTypes.EmployeePartnerUpdated,
        element: intl.formatMessage({ id: 'events.type.employeePartnerUpdated' }),
      },
      {
        value: ConnectionEventTypes.GrossWageUpdated,
        element: intl.formatMessage({ id: 'events.type.grossWageUpdated' }),
      },
      {
        value: ConnectionEventTypes.EmployeeLeftCompany,
        element: intl.formatMessage({ id: 'events.type.employeeLeftCompany' }),
      },
      {
        value: ConnectionEventTypes.NewPeriodicWage,
        element: intl.formatMessage({ id: 'events.type.newPeriodicWage' }),
      },
      {
        value: ConnectionEventTypes.ReEmployed,
        element: intl.formatMessage({ id: 'events.type.reEmployed' }),
      },
      {
        value: ConnectionEventTypes.NewParticipantBasis,
        element: intl.formatMessage({ id: 'events.type.newParticipantBasis' }),
      },
    ];
  }, [intl]);

  return React.useMemo(() => ({ connectionEventTypes }), [connectionEventTypes]);
};
