import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { Box, Grid } from '@mui/material';

import { TranslationKeys } from '../../../../../translations';

import { ActionButton, InputFieldFactory, SearchInput } from '../../../../../components';

import { CheckboxStyled, DivRootStyled, TypographyCheckBoxHeaderStyled } from './ReportToolbar.styles';
import { ReportTypeEnum } from '../../../../../types';

type ReportToolbarProps = {
  isReportWithConnections: boolean;
  onChangeSelectAll: (checked: boolean) => void;
  onChangeSearchQuery: (searchQuery: string) => void;
  onClickGenerateReport: () => void;
  isAllSelected?: boolean;
  initialSearchQuery?: string;
  disabledActionsWhileLoading?: boolean;
  onChangeEmploymentEndDate: (date: string) => void;
  onChangeReportType: (reportType: ReportTypeEnum) => void;
  selectedReportType: string;
};

export const ReportToolbar = ({
  isReportWithConnections,
  onChangeSelectAll,
  onChangeSearchQuery,
  onClickGenerateReport,
  initialSearchQuery,
  isAllSelected = false,
  disabledActionsWhileLoading = false,
  onChangeEmploymentEndDate,
  selectedReportType,
}: ReportToolbarProps) => {
  const intl = useIntl();

  const handleOnChangeSelectAll = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked;
      onChangeSelectAll(checked);
    },
    [onChangeSelectAll],
  );

  const handleOnChangeSearchQuery = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeSearchQuery(event.target.value);
    },
    [onChangeSearchQuery],
  );

  const handleOnChangeEmploymentEndDate = React.useCallback(
    (event: { name: string; value: string }) => {
      onChangeEmploymentEndDate(event.value);
    },
    [onChangeEmploymentEndDate],
  );

  const CheckboxHeaderText = React.useMemo(() => {
    let textId = '';

    if (!isReportWithConnections) {
      textId = TranslationKeys.reports_select_employers;
    }
    if (isReportWithConnections) {
      textId = TranslationKeys.reports_select_employersAndConnections;
    }

    return (
      <TypographyCheckBoxHeaderStyled variant={'h5'}>
        {textId !== '' ? <FormattedMessage id={textId} /> : textId}
      </TypographyCheckBoxHeaderStyled>
    );
  }, [isReportWithConnections]);

  return (
    <DivRootStyled>
      <Grid container spacing={3} paddingLeft={'20px'}>
        {selectedReportType !== ReportTypeEnum.ControlTool && (
          <Grid item xs={12} md>
            <Box lineHeight={'1.5em'}>
              {CheckboxHeaderText}

              <CheckboxStyled
                name={'selectAllRows'}
                label={<FormattedMessage id={TranslationKeys.global_selectAll} />}
                checked={isAllSelected}
                onChange={handleOnChangeSelectAll}
                disabled={disabledActionsWhileLoading}
              />
            </Box>
          </Grid>
        )}
        {(selectedReportType.toString() === ReportTypeEnum.EmployeeInformation ||
          selectedReportType.toString() === ReportTypeEnum.ControlTool) && (
          <Grid item xs={12} md>
            <InputFieldFactory
              key={`ib-report-employment-end-date-filter`}
              disabled={
                selectedReportType.toString() !== ReportTypeEnum.EmployeeInformation &&
                selectedReportType.toString() !== ReportTypeEnum.ControlTool
              }
              onChange={handleOnChangeEmploymentEndDate}
              field={{
                type: 'date',
                name: TranslationKeys.global_dateTo,
                label: intl.formatMessage({ id: TranslationKeys.global_dateTo }),
                fullWidth: true,
                value: null,
                debounceTime: 3000,
                required: false,
                validate: false,
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md>
          <SearchInput
            onChange={handleOnChangeSearchQuery}
            initialSearchQuery={initialSearchQuery}
            disabled={disabledActionsWhileLoading}
          />
        </Grid>
        <Grid item xs={12} md>
          <Grid container justifyContent={'flex-end'}>
            <ActionButton
              messageId={TranslationKeys.reports_button_generate}
              onClick={onClickGenerateReport}
              disabled={disabledActionsWhileLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </DivRootStyled>
  );
};
