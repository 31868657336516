import type { Owner } from './owner.types';
import type { Employer, EmployerConnection } from './employer.types';

export enum ReportTypeEnum {
  ActiveEmployeeCount = 'active_employee_count',
  EmployeeInformation = 'employee_information',
  SummaryOfRegulationDeviations = 'summary_of_regulation_deviations',
  InterruptedSynchronization = 'interrupted_synchronization',
  LatestConnectionRun = 'latest_connection_run',
  CollectiveSalaryChange = 'collective_salary_change',
  ControlTool = 'control_tool',
}

export type ReportEmployerConnection = Pick<EmployerConnection, '@id' | '@type' | 'connectionId'> & {
  name: string;
  manual: boolean;
  regularPensionScheme: boolean;
};

export type ReportEmployer = Pick<Employer, '@id' | '@type' | 'employerId'> & {
  name: string;
  connections: Array<ReportEmployerConnection>;
  subEmployers?: Array<ReportEmployer>;
  eventFlowDefaultUser?: string;
};

export type ReportSubOwner = Pick<Owner, '@id' | '@type'> & {
  subOwnerId: Owner['ownerId'];
  name: string;
  employers: Array<ReportEmployer>;
  eventFlowDefaultUser?: string;
};

export type Report = {
  '@id': string;
  '@type': string;
  ownerId: Owner['ownerId'];
  employers?: Array<ReportEmployer>;
  subOwners?: Array<ReportSubOwner>;
};
