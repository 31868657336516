import React from 'react';
import Grid from '@mui/material/Grid';
import {
  FilterableTable,
  type FilterableTableColumnType,
  type FilterableTableRowMapperFunc,
  KebabMenu,
  type KebabMenuItem,
  SnackBar,
} from '../../../../../components';
import { FormattedMessage } from 'react-intl';
import { TranslationKeys } from '../../../../../translations';
import { useStoreSelector } from '../../../../../store';
import { useConnectionGetOCRFilesDataQuery } from '../../../../../hooks/api/connection/useConnectionGetOCRFilesDataQuery';
import { useParams } from 'react-router-dom';
import type { OCFFile } from '../../../../../types/Connection/ocrFile.types';
import { useConnectionUpdateOCRFileStatusMutation } from '../../../../../hooks/api/connection/useConnectionUpdateOCRFileStatusMutation';

const TABLE_COLUMNS = {
  TITLE: 'title',
  STATUS: 'status',
  DATE: 'date',
  AVERAGE_ACCURACY: 'averageAccuracy',
  ROW_COUNT: 'rowCount',
  ACTIONS: 'actions',
};

const OCR_FILE_STATUS = {
  APPROVED: 'approved',
  FAILED: 'failed',
};

export const OCRFilesTab = () => {
  const { showSnackBar } = useStoreSelector(state => state.AppReducer);
  const { connectionId } = useParams();
  const [perPage, setPerPage] = React.useState(10);
  const [page, setPage] = React.useState(1);

  const { mutate: updateOCRFileStatus } = useConnectionUpdateOCRFileStatusMutation();
  const { data: ocrFiles, refetch: reFetchOCRFiles } = useConnectionGetOCRFilesDataQuery({
    variables: {
      connectionId: connectionId,
      perPage,
      page,
    },
  });

  const pagination = React.useMemo(() => {
    return {
      perPage,
      totalCount: ocrFiles?.totalCount ?? 0,
      page,
    };
  }, [ocrFiles?.totalCount, page, perPage]);

  const paginationChange = React.useCallback(({ rowSize, page }) => {
    if (page) {
      setPage(page);
    }
    if (rowSize) {
      setPerPage(rowSize);
    }
  }, []);

  const buildRowActions = React.useCallback(
    (data: OCFFile) => {
      const disabled = data.status !== 'waiting_on_approval';

      const actions: Array<KebabMenuItem> = [
        {
          element: <FormattedMessage id={TranslationKeys.connection_processes_button_approve} />,
          disabled: disabled,
          onClick: () => {
            updateOCRFileStatus(
              {
                connectionId,
                id: data.id,
                status: OCR_FILE_STATUS.APPROVED,
              },
              {
                onSuccess: () => {
                  reFetchOCRFiles();
                },
              },
            );
          },
        },
        {
          element: <FormattedMessage id={TranslationKeys.connection_processes_button_decline} />,
          disabled: disabled,
          onClick: () => {
            updateOCRFileStatus(
              {
                connectionId,
                id: data.id,
                status: OCR_FILE_STATUS.FAILED,
              },
              {
                onSuccess: () => {
                  reFetchOCRFiles();
                },
              },
            );
          },
        },
      ];

      return <KebabMenu items={actions} />;
    },
    [connectionId, updateOCRFileStatus, reFetchOCRFiles],
  );

  const tableRowMapper = React.useCallback<FilterableTableRowMapperFunc<OCFFile>>(
    data => {
      return {
        data: {
          [TABLE_COLUMNS.TITLE]: 'Verzamelloonstaten ' + (data.year ?? ''),
          [TABLE_COLUMNS.STATUS]: data.status,
          [TABLE_COLUMNS.DATE]: data.uploadDate,
          [TABLE_COLUMNS.AVERAGE_ACCURACY]: data.averageAccuracy ?? '-',
          [TABLE_COLUMNS.ROW_COUNT]: data.rowCount ?? '-',
          [TABLE_COLUMNS.ACTIONS]: buildRowActions(data),
        },
      };
    },
    [buildRowActions],
  );

  const tableHeaders = React.useMemo<Array<FilterableTableColumnType>>(() => {
    return [
      {
        name: TABLE_COLUMNS.TITLE,
        title: <FormattedMessage id={TranslationKeys.global_title} />,
      },
      {
        name: TABLE_COLUMNS.STATUS,
        title: <FormattedMessage id={TranslationKeys.global_status} />,
      },
      {
        name: TABLE_COLUMNS.DATE,
        title: <FormattedMessage id={TranslationKeys.global_date} />,
      },
      {
        name: TABLE_COLUMNS.AVERAGE_ACCURACY,
        title: <FormattedMessage id={TranslationKeys.ocr_average_accuracy} />,
      },
      {
        name: TABLE_COLUMNS.ROW_COUNT,
        title: <FormattedMessage id={TranslationKeys.ocr_row_count} />,
      },
      {
        name: TABLE_COLUMNS.ACTIONS,
        isActionsColumn: true,
      },
    ];
  }, []);

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <FilterableTable
            loading={false}
            columns={tableHeaders}
            rows={ocrFiles?.data ?? []}
            rowMapper={tableRowMapper}
            pagination={pagination}
            onPaginationChange={paginationChange}
          />
        </Grid>
      </Grid>
      <SnackBar message={<FormattedMessage id={TranslationKeys.events_tooManyEvents} />} open={showSnackBar} />
    </>
  );
};
