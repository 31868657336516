import type { Dictionary } from '../../utils';

export enum FileType {
  Unknown = 'unknown',
  Excel = 'excel',
  Csv = 'csv',
  Xml = 'xml',
  PDF = 'pdf',
}

export const FilesTypesToMime: Dictionary<string[]> = {
  excel: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
  csv: ['text/csv'],
  xml: ['application/xml', 'text/xml'],
  pdf: ['application/pdf'],
};

export const ExtensionToFileType: Dictionary<FileType> = {
  xlsx: FileType.Excel,
  xls: FileType.Excel,
  csv: FileType.Csv,
  xml: FileType.Xml,
};

export const MimeToFileType = Object.keys(FilesTypesToMime).reduce((map: Dictionary<string>, type: string) => {
  const mimes = FilesTypesToMime[type];
  mimes.forEach(mime => {
    map[mime] = type;
  });
  return map;
}, {});
