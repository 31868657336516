import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Table, TableBody, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import { EventContentTableRow } from '../EventContentTableRow';
import formatter from 'app/utils/formatter';

const EventContentTable = ({ event, ignoreKeys, keyRenderers, collapse }) => {
  const [expanded, setExpanded] = useState(false);
  const { type } = event;

  const renderEventKey = key => {
    // render wage components' values as currency
    const renderer = key === 'value' && type === 'wage' ? keyRenderers.wage : keyRenderers[key];
    return <EventContentTableRow key={key} header={key} value={event[key]} renderer={renderer} />;
  };

  const content = (
    <Table>
      <TableBody>
        {Object.keys(event)
          .filter(key => !ignoreKeys.includes(key))
          .map(renderEventKey)}
      </TableBody>
    </Table>
  );

  if (!collapse) {
    return content;
  }

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} />
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};

EventContentTable.propTypes = {
  event: PropTypes.object.isRequired,
  keyRenderers: PropTypes.object,
  collapse: PropTypes.bool,
  ignoreKeys: PropTypes.array,
};

EventContentTable.defaultProps = {
  collapse: false,
  ignoreKeys: ['@id', 'mongoId', 'connectionId', '@type', 'eventType'],
  keyRenderers: {
    correction: formatter.yesNoEmpty,
    wage: formatter.currency,
    annualWage: formatter.currency,
    hrEmployee: value => <span>{`${value.personName.fullName} (${value.syncStatus})`}</span>,
    processed: value => (value ? <CheckIcon /> : <ClearIcon />),
    archived: value => (value ? <CheckIcon /> : <ClearIcon />),
    periodStart: formatter.date,
    periodEnd: formatter.date,
    periodWage: formatter.currency,
    hourlyWage: formatter.currency,
    cumulativeWage: formatter.currency,
    createdAt: formatter.dateTime,
    eventDate: formatter.systemDate,
    startDate: formatter.systemDate,
    dateStart: formatter.date,
    endDate: formatter.systemDate,
    dateOfBirth: formatter.date,
    startDateOfEmployment: formatter.date,
    endDateOfEmployment: formatter.date,
    employmentStart: formatter.systemDate,
    employmentEnd: formatter.systemDate,
    probationDate: formatter.date,
    percentageStartDate: formatter.date,
    relationStartDate: formatter.date,
    relationEndDate: formatter.date,
    grossWage: formatter.currency,
    svWage: formatter.currency,
    aofWkoPremium: formatter.currency,
    children: value =>
      value.length > 0 &&
      value.map(child => (
        <EventContentTable key={child['@id']} event={child} ignoreKeys={['@key', '@type', '@id']} collapse />
      )),
    address: value =>
      value && (
        <>
          <Typography component="p">
            <span>{`${value.street} ${value.houseNumber}`}</span>
            {value.houseNumberSuffix && <span>{` ${value.houseNumberSuffix}`}</span>}
          </Typography>
          <Typography component="p">{`${value.zipCode} ${value.city}`}</Typography>
          <Typography>{value.country}</Typography>
        </>
      ),
    previousAnnualWage: formatter.currency,
    previousDateStart: formatter.date,
  },
};

export default EventContentTable;
