import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { EmployerManagementPage } from './EmployerManagementPage';
import EmployerAddPage from './EmployerAddPage';
import EmployerDetailPage from './EmployerDetailPage';
import { useBreadcrumb } from 'app/components/Breadcrumbs';
import { EmployerInformationTab } from './EmployerDetailPage/EmployerInformationTab';
import EmployeePages from '../../Employees/Pages';
import { RestrictAccess } from 'app/components/Authorization';
import { BATCH, CONNECTION, EMPLOYER, EVENT } from 'app/common/Authorization/entities';
import { DOWNLOAD, EDIT, VIEW } from 'app/common/Authorization/permissions';
import { EmployerSummaryTab } from './EmployerDetailPage/EmployerSummaryTab';
import ConnectionDetailsPage from '../../Connections/Pages/ConnectionDetailsPage';
import ConnectionInfoTab from '../../Connections/Pages/ConnectionDetailsPage/ConnectionInfoTab';
import EventsTab from '../../Connections/Pages/ConnectionDetailsPage/EventsTab';
import BatchesTab from '../../Connections/Pages/ConnectionDetailsPage/BatchesTab';
import DownloadsTab from '../../Connections/Pages/ConnectionDetailsPage/DownloadsTab';
import { EmployeeOverviewTab } from './EmployerDetailPage/EmployeeOverviewTab';
import { CompareBatchPage } from '../../Connections/Pages/CompareBatchPage';
import { EmployerFilesTab } from './EmployerDetailPage/EmployerFilesTab';
import { OCRFilesTab } from '../../Connections/Pages/ConnectionDetailsPage/OCRFilesTab';

const EmployerPages = () => {
  useBreadcrumb('menu.employers');
  return (
    <Routes>
      <Route element={<RestrictAccess entity={EMPLOYER} permission={EDIT} />}>
        <Route element={<EmployerAddPage />} path="add" />
      </Route>
      <Route element={<RestrictAccess entity={EMPLOYER} permission={VIEW} />}>
        <Route element={<EmployerManagementPage />} path="overview" />
        <Route element={<EmployerDetailPage />} path=":employerId/*">
          <Route path="info" element={<EmployerInformationTab />} />
          <Route path="summary" element={<EmployerSummaryTab />} />
          <Route path="employees">
            <Route index element={<EmployeeOverviewTab />} />
            <Route path=":employeeId/*" element={<EmployeePages />} />
          </Route>
          <Route path="files">
            <Route index element={<EmployerFilesTab />} />
          </Route>
          <Route path="connections">
            <Route element={<ConnectionDetailsPage />} path={`:connectionId/*`}>
              <Route element={<ConnectionInfoTab />} path={`info`} />
              <Route element={<RestrictAccess entity={EVENT} permission={VIEW} />}>
                <Route element={<EventsTab />} path={`events`} />
              </Route>

              <Route element={<RestrictAccess entity={BATCH} permission={VIEW} />}>
                <Route path={`batches`}>
                  <Route index element={<BatchesTab />} />
                  <Route element={<CompareBatchPage />} path={`:batchId/compare`} entity={BATCH} permission={EDIT} />
                </Route>
              </Route>

              <Route element={<RestrictAccess entity={CONNECTION} permission={DOWNLOAD} />}>
                <Route element={<DownloadsTab />} path={`downloads`} />
              </Route>

              <Route element={<RestrictAccess entity={CONNECTION} permission={VIEW} />}>
                <Route element={<OCRFilesTab />} path={`files`} />
              </Route>

              <Route index element={<Navigate to="info" replace />} />
              <Route path="*" element={<Navigate to="info" replace />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="info" replace />} />
          <Route index element={<Navigate to="info" replace />} />
        </Route>
        <Route index element={<Navigate to="overview" replace />} />
        <Route path="*" element={<Navigate to="overview" replace />} />
      </Route>
    </Routes>
  );
};

export default EmployerPages;
